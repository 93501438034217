.label.order_from_listitem {
    background-color:transparent;
    color: black;
}

.label.order_from_listitem_de {
    background-color:red;
    color: white;
}

